import React, {useState} from 'react';
import { motion } from "framer-motion"
import { getThemeColor } from '../utils/colors';

const NextButton = ({onClick, text, bg, color, delay}) => {
  const bgColor = getThemeColor(bg, "brown");
  const textColor = getThemeColor(color, "gold");

  return <motion.button
          initial={{scale: .7, opacity: 0}}
          animate={{scale: 1, opacity: 1}}
          transition={{duration: .5, delay:delay}}
          onClick={()=>onClick()}
          style={{background:bgColor,transition:"bg 250ms ease-in-out", color:textColor}}
          className="uppercase rounded-full text-4xl font-bold my-1 font-sans w-full next-button flex justify-center items-center">
      {text}
    </motion.button>
}

export default NextButton;
