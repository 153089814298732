module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    colors: {
      white: {
        DEFAULT:"#ffffff",
      },
      gold: {
        DEFAULT: "#ff9a00",
      },
      red: {
        DEFAULT: "#fa1900",
      },
      blue: {
        DEFAULT: "#002b3f"
      },
      brown: {
        //DEFAULT: "#723c0e"
        DEFAULT: "#632D0F"
      }
    },
    fontFamily: {
       'helvetica-neue': ["Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "sans-serif"],
       'sans': ["HelveticaNow", "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "sans-serif"],
       'bebas': ["bebas-neue",'sans-serif']
    },
    extend: {},
  },
  plugins: [],
}
