import {theme} from "../../tailwind.config.js";

export const getThemeColor = (color, defaultColor) => {
  if(defaultColor){
    return (theme.colors[color] && theme.colors[color].DEFAULT) || theme.colors[defaultColor].DEFAULT;
  }else if(color){
    return (theme.colors[color] && theme.colors[color].DEFAULT)
  }
  return null;
}
