import React, {useState, useRef, useEffect} from "react"
import NextButton from "./NextButton";
import Helmet from 'react-helmet'
import {getThemeColor} from '../utils/colors';
import { withPrefix } from "gatsby";

const Mp4 = ({bg, buttonText, button, buttonTextColor, src, loop, onFinished, hideNextButton = false}) => {

  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);

  const video = useRef();
  const timer = useRef();

  useEffect(() => {
    timer.current = window.setTimeout(()=>{
      onFinished();
    }, 5000)
  }, [src]);

  const handlePlay = () => {
    clearTimeout(timer.current);
    setPlaying(true);
  }

  const handleError = (e) => {
    console.log(e);
    onFinished()
  }

  const bgColor = getThemeColor(bg, "gold");

  return <>
  <Helmet>
    <meta name="theme-color" content={bgColor} />
  </Helmet>
  <div className={`video-container h-full w-full`} style={{background:bgColor}}>
    <div className="lottie-aspect">
      <video className="absolute" ref={video} autoPlay={true} loop={loop} muted={true} playsInline={true} onEnded={onFinished} onLoad={setPlaying} onPlay={handlePlay} onError={handleError} src={withPrefix(src)}>
      </video>
    </div>
    {loop && !hideNextButton ?
      <div className="button-area absolute bottom-0">
        <NextButton onClick={onFinished} text={ buttonText || "Let's Go"} bg={button} color={buttonTextColor}/>
      </div>
    : null }
  </div></>
}

export default Mp4;
